'use client'

import { useRef } from 'react'

import useMediaQuery from '@equifax-ui/hooks/useMediaQuery'
import isClient from '@equifax-ui/utils/browser/isClient'

import { usePosition } from '@/hooks/usePosition'

import { Container, Title } from '@/components'

import { Result } from './components'

import { RESULTS } from './contants'

import styles from './styles.module.scss'

export const Results = () => {
  const ref = useRef()
  const windowInnerHeight = isClient() && window.innerHeight

  const { isVisibleOnScreen } = usePosition({
    startRef: ref,
    endRef: ref,
    comparisonLimit: {
      top: windowInnerHeight / 4,
      bottom: windowInnerHeight / 2
    }
  })

  const isDesktop = useMediaQuery('screen and (min-width: 1024px)')

  return (
    <section ref={ref} className={styles.results}>
      <Container>
        <Title
          color="white"
          align={isDesktop && 'center'}
          text={
            <>
              Por que escolher a <b>Equifax | BoaVista?</b>
            </>
          }
        />

        <div className={`${styles.results__list}`}>
          {RESULTS.map((result, index) => (
            <Result
              {...result}
              key={`result-${index}`}
              isVisibleOnScreen={isVisibleOnScreen}
            />
          ))}
        </div>
      </Container>
    </section>
  )
}
