import { RoundIcon } from '@/components'

import styles from './PlansHeader.module.scss'

export const PlansHeader = ({ icon, title }) => {
  return (
    <div className={styles.plansHeader}>
      <div className={styles.plansHeader__icon}>
        <RoundIcon
          icon={icon}
          size="large"
          iconColor="red"
          bgColor="white"
          border="thin"
        />
      </div>

      <p className={styles.plansHeader__title}>{title}</p>
    </div>
  )
}
