export const ADVANTAGES = [
  {
    icon: 'equifax-laptop-graphic',
    title: 'Aumento de eficiência',
    text: 'Tome decisões de crédito mais rápidas, identificando clientes de alto risco'
  },
  {
    icon: 'equifax-money-bag-data',
    title: 'Redução de custos',
    text: 'Diminua despesas com análises manuais e relatórios extensos.'
  },
  {
    icon: 'equifax-cloud-connections',
    title: 'Tecnologia global em nuvem',
    text: 'Ideal para empresas que não possuem departamento de análise de crédito, plataforma ajustável ao tamanho do seu negócio.'
  },
  {
    icon: 'equifax-cloud-security',
    title: 'Segurança',
    text: 'Segurança de dados em conformidade com as regulamentações.'
  },
  {
    icon: 'equifax-file-check',
    title: 'Checagem simples',
    text: 'Verificação em tempo real, com sugestões claras e objetivas.'
  }
]
