export const ANCHORS = [
  {
    icon: '',
    label: 'Conhecer',
    text: 'Pós-pago',
    id: 'hero-card-postpaid',
    url: '#'
  },
  {
    icon: '',
    label: 'Conhecer',
    text: 'Pré-pago',
    id: 'hero-card-prepaid',
    url: '#'
  },
  {
    icon: '',
    label: 'Conhecer',
    text: 'Antifraude',
    id: 'hero-card-antifraud',
    url: '#'
  },
  {
    icon: '',
    label: 'Consultar',
    text: 'Meu CPF',
    id: 'hero-card-cpf',
    url: '#'
  }
]
