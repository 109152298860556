'use client'

import { redirect } from 'next/navigation'

import { useProposalContext } from '@/context/ProposalContext'

import usePageLayout from '@/hooks/useLayout'

import { Contact, Help, Hero, Hiring, Plans } from '@/sections'

import { Banner, BannerButtons, Footer, Paywall } from '@/components'

import {
  Advantages,
  Interconnect,
  Solutions,
  Representative,
  Results,
  Plans as PlansList
} from './sections'

const redirectToHome = () => {
  const isOrganic = process.env.NEXT_PUBLIC_BUILD_TYPE === 'organic'

  // eslint-disable-next-line no-undef
  if (!isOrganic && environmentId === 'production') {
    redirect('/')
  }
}

export default function Home() {
  redirectToHome()
  usePageLayout()

  const { isPaywallOpen } = useProposalContext()

  return (
    <main>
      {isPaywallOpen && <Paywall />}

      <Banner
        isAnimated
        align="center"
        text="A Boa Vista mudou e agora é Equifax | BoaVista!"
      />

      <Hero
        variant="home"
        title="Ajudamos sua empresa a tomar decisões de crédito mais seguras e reduzir o risco de inadimplência."
        subtitle="Não importa o seu modelo de negócio, nós podemos te ajudar"
      />

      <Plans />
      <Advantages />
      <Hiring />
      <Solutions />
      <Interconnect />
      <PlansList planType="prepaid" />
      <Representative />
      <Help shouldRenderFilters />
      <Results />
      <Contact variant="secondary" />
      <Footer />

      <Banner
        isFixed
        hasBorder
        hideTextOnMobile
        text="Central de vendas Equifax | BoaVista"
      >
        <BannerButtons />
      </Banner>
    </main>
  )
}
