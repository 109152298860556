import { Card, Icon, If, Loader } from '@/components'

import styles from './AnchorCard.module.scss'

export const AnchorCard = ({ id, label, highlight_text, loadingPlanID }) => {
  return (
    <Card
      size="medium"
      id={`hero-plan-card--${id}`}
      customClass={`${styles.plan} ${styles[`plan--${id}`]} --gtm-click`}
      onClick={() => {}}
    >
      <If
        condition={loadingPlanID === id}
        renderIf={<Loader customClass={styles.plan__loader} />}
        renderElse={
          <>
            <div className={styles.plan__highlights}>
              <Icon
                size="xs"
                name="equifax-arrow-up-right"
                className={styles.plan__icon}
              />
            </div>

            <div className={styles.plan__content}>
              <div className={styles.plan__name}>
                <Icon name="money-coin" size="small" />
                <p>{label}</p>
              </div>

              <p className={styles.plan__price}>{highlight_text}</p>
            </div>
          </>
        }
      />
    </Card>
  )
}
