'use client'

import { useState } from 'react'

import { Container, Tag, Title } from '@/components'

import { Solution } from './components'

import { SOLUTIONS } from './contants'

import styles from './styles.module.scss'

export const Solutions = () => {
  const [expandedCardIndex, setExpandedCardIndex] = useState()

  return (
    <section className={styles.solutions}>
      <Container>
        <Tag text="Soluções" bgColor="white" />

        <Title
          customClass={styles.solutions__title}
          text={
            <>
              Nossas soluções <b>impactam diretamente</b> a saúde do seu
              negócio.
            </>
          }
        />

        <div className={`${styles.solutions__list}`}>
          {SOLUTIONS.map((solution, index) => (
            <Solution
              {...solution}
              index={index}
              key={`solution-card-${index}`}
              expandedCardIndex={expandedCardIndex}
              setExpandedCardIndex={setExpandedCardIndex}
            />
          ))}
        </div>
      </Container>
    </section>
  )
}
