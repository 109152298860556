import classnames from 'classnames'

import useClickWithScroll from '@equifax-ui/hooks/useClickWithScroll'

import { Button, Container, Icon, Title } from '@/components'

import { ADVANTAGES } from './constants'

import styles from './Advantages.module.scss'

export const Advantages = () => {
  const { onClickItem } = useClickWithScroll()

  return (
    <section
      className={classnames(styles.advantages, {
        [styles['advantages--animated']]: false
      })}
    >
      <Container>
        <Title
          align="left"
          text={
            <>
              Conheça as principais vantagens de contratar um{' '}
              <b>plano Pós-pago</b> para o seu negócio:
            </>
          }
        />

        <div className={styles.advantages__list}>
          {ADVANTAGES.map(({ icon, text }, index) => (
            <div
              key={`advantage-${index}`}
              className={`${styles.advantages__item}`}
            >
              <Icon name={icon} size="small" color="primary" />
              <p>{text}</p>
            </div>
          ))}
        </div>

        <Button
          variant="primary"
          id="advantages-wantToHire-button"
          className={styles.advantages__button}
          onClick={() => onClickItem('proposal', 4)}
        >
          Quero contratar
        </Button>
      </Container>
    </section>
  )
}
