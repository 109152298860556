import PostpaidImage from '/public/images/postpaid.webp'
import PrepaidImage from '/public/images/prepaid.webp'

export const PLANS_DATA = {
  prepaid: {
    tag_text: 'Recargas Pré-Pago',
    subtitle: (
      <>
        A recarga Pré é <b>perfeita para consultas pontuais</b>. Recarregou,
        consultou!
      </>
    ),
    icon: 'money-coin',
    badge_text: 'Mais vendido',
    redirect_url: `${urlEquifaxOrganic}/consultas-pre-pago/`, // eslint-disable-line no-undef
    image: {
      src: PrepaidImage,
      alt: 'homem olhando para a tela de um notebook'
    },
    hiring_steps: [
      {
        icon: 'equifax-pointer-check',
        description: 'Selecione o valor da recarga'
      },
      {
        icon: 'equifax-purchase',
        description: 'Realize a compra'
      },
      {
        icon: 'equifax-restricted-access',
        description: 'Receba sua senha de acesso'
      },
      {
        icon: 'equifax-file-analysis',
        description: 'Pronto! Já pode consultar'
      }
    ]
  },
  postpaid: {
    title:
      'Veja nossos planos e encontre o que é perfeito para suas necessidades.',
    subtitle: 'Contratar seu plano Pós-Pago é muito fácil!',
    icon: 'equifax-report',
    badge_text: 'Recomendado',
    tag_text: 'Planos Pós-Pago',
    redirect_url: `${urlEquifaxOrganic}/planos`, // eslint-disable-line no-undef
    image: {
      src: PostpaidImage,
      alt: 'mulher olhando para a tela de um notebook'
    },
    hiring_steps: [
      {
        icon: 'equifax-pointer-check',
        description: 'Selecione o plano'
      },
      {
        icon: 'equifax-file-export',
        description: 'Envie seus dados'
      },
      {
        icon: 'equifax-file-analysis',
        description: 'Aguarde nossa análise'
      },
      {
        icon: 'equifax-business-agreement',
        description: 'Contratado! Aproveite todos os benefícios'
      },
      {
        icon: 'equifax-bill',
        description: '1º boleto gerado em até 45 dias'
      }
    ]
  }
}
