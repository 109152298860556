import { Container } from '@/components'

import { AnchorCard } from './components/AnchorCard/AnchorCard'

import { ANCHORS } from './constants'

import styles from './Hero.module.scss'

export const Hero = () => {
  return (
    <section className={styles.hero}>
      <Container classname={styles.hero__wrapper}>
        <div className={styles.hero__content}>
          <h1 className={styles.hero__title}>Somos a Equifax | BoaVista</h1>
          <h2 className={styles.hero__subtitle}>
            Uma empresa global que oferece dados para análise de crédito de
            pessoas e empresas.
          </h2>

          <div className={styles.hero__cards}>
            {ANCHORS.map(({ id, icon, label, text, url }) => (
              <AnchorCard
                id={id}
                key={id}
                icon={icon}
                label={label}
                url={url}
                highlight_text={<b>{text}</b>}
              />
            ))}
          </div>
        </div>
      </Container>
    </section>
  )
}
