import { Icon } from '@/components'

import * as styles from './Advantage.module.scss'

export const Advantage = ({ icon, title, text }) => {
  return (
    <div className={styles.advantage}>
      <Icon size="medium" color="primary" name={icon} />
      <div>
        <p className={styles.advantage__text}>
          <b>{title}</b>
        </p>
        <p className={styles.advantage__text}>{text}</p>
      </div>
    </div>
  )
}
