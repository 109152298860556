import { Advantages } from './Advantages/Advantages'
import { Hero } from './Hero/Hero'
import { Interconnect } from '../../home/sections/Interconnect/Interconnect'
import { Plans } from './Plans/Plans'
import { Representative } from './Representative/Representative'
import { Results } from './Results/Results'
import { Solutions } from './Solutions/Solutions'

export {
  Advantages,
  Hero,
  Interconnect,
  Plans,
  Representative,
  Results,
  Solutions
}
